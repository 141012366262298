<template>
  <div>
    <div
      class="hero-image mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="hero-text">
        <h1>Juice Fasting at Amchara</h1>
      </div>
    </div>

    <!-- What is juice fasting section -->
    <div class="container mt-4">
      <div class="row text-center">
        <div class="col-md-9 mx-auto">
          <h3 style="color: #83c550">What is Juice Fasting?</h3>
          <p>
            Juice fasting is the choice to abstain from food for a period of
            days and instead consume healthy, vegetable based juices. Choosing
            to juice helps your body to pre-digest nutrients in the food, which
            increases absorption and gives your digestion a rest.
          </p>
          <p>
            Normally we recommend juicing with organic vegetables and the
            addition of lemon juice and root ginger or turmeric can also enhance
            the flavour and provide additional health benefits. Sometimes a
            minimal amount of fruit is also added to these juices from apples
            and pears. This is particularly useful for those new to juice
            fasting and adjusting to the new flavours.
          </p>

          <img
            src="@/assets/images/pages/juice-fasting/what-is-juice-fasting.jpeg"
            width="500"
            class="my-4 img-fluid"
            alt="What is Juice Fasting"
          />
        </div>
      </div>
    </div>

    <div class="container pt-2 mb-4">
      <div class="row">
        <div class="col-md-12 text-center">
          <h3 style="color: #83c550">Types of Fasting</h3>
        </div>
      </div>

      <!-- Circle Slider -->
      <div class="container my-2 circle_slider" data-slider-id="1">
        <div class="row noselect">
          <div class="col-auto mx-auto">
            <div class="mx-auto">
              <!-- small green outer circle left -->
              <div
                class="green_circle outer_circle d-none d-lg-inline-block"
                data-position="outer_left"
              >
                <div class="green_circle_outer_left_text"></div>
                <div class="green_circle_outer_arrow"></div>
              </div>
              <!-- large green inner circle left -->
              <div
                class="green_circle inner_circle d-none d-lg-inline-block"
                data-position="inner_left"
              >
                <div class="green_circle_inner_left_text"></div>
                <div class="green_circle_inner_arrow"></div>
              </div>
              <div class="circle_slider_mobile_elements">
                <!-- left arrow on mobiles -->
                <div class="slider-arrow d-lg-none mx-3" data-rotate="left">
                  <i class="fa fa-angle-left fa-4x" style="color: grey"></i>
                </div>
                <!-- large selected centre circle -->
                <div class="selected_circle selected_circle_orange">
                  <div
                    class="selected_circle_text selected_circle_text_orange"
                  ></div>
                  <div
                    class="selected_circle_arrow selected_circle_arrow_orange"
                  ></div>
                </div>
                <!-- right arrow on mobiles -->
                <div class="slider-arrow d-lg-none mx-3" data-rotate="right">
                  <i class="fa fa-angle-right fa-4x" style="color: grey"></i>
                </div>
              </div>
              <!-- large green inner circle right -->
              <div
                class="green_circle inner_circle d-none d-lg-inline-block"
                data-position="inner_right"
              >
                <div class="green_circle_inner_right_text"></div>
                <div class="green_circle_inner_arrow"></div>
              </div>
              <!-- small green outer circle right -->
              <div
                class="green_circle outer_circle d-none d-lg-inline-block"
                data-position="outer_right"
              >
                <div class="green_circle_outer_right_text"></div>
                <div class="green_circle_outer_arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-9 mx-auto">
            <!-- large selected centre circle subheader and description -->
            <div class="text-center">
              <div
                class="
                  selected_circle_subheader selected_circle_subheader_orange
                  my-3
                "
              ></div>
              <div class="selected_circle_description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <hr width="80%" />
        </div>
      </div>
    </div>

    <!-- Benefits Section -->
    <div class="container mt-4">
      <div class="row">
        <div class="container">
          <benefits-of-juice-fasting></benefits-of-juice-fasting>
        </div>
      </div>
    </div>

    <div class="container py-4">
      <div class="row">
        <div class="col-xs-12 col-md-9 mx-auto text-center">
          <h2 style="color: #83c550">
            Fasting affects biochemical changes within the body. Some of the
            major changes that occur are:
          </h2>

          <h6 class="main-heading">Reductions in IGF-1</h6>
          <p>
            IGF-1 has a growth promoting effect on your body. High levels have
            been associated with cancer, arthritis, diabetes and accelerated
            ageing.
          </p>

          <h6 class="main-heading">Increase in ketone bodies</h6>
          <p>
            These are the chemicals that the body uses to break down fat for
            energy in the absence of sugar.
          </p>

          <h6 class="main-heading">Decrease in glucose</h6>
          <p>
            Too much glucose (sugar) increases your risk for heart disease,
            fatty liver, metabolic syndrome and Type 2 diabetes.
          </p>

          <h6 class="main-heading">Enhance complete body health</h6>
          <p>
            Fasting can reduce oxidative damage and inflammation, as well as
            optimising energy metabolism. Research suggests fasting can also
            enhance cellular protection and extend longevity, in part by
            reprogramming metabolic and stress resistance pathways.
          </p>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <hr width="80%" />
        </div>
      </div>
    </div>

    <div class="container-fluid py-4">
      <div class="container">
        <div class="row text-center">
          <div class="col-xs-12 col-md-9 mx-auto">
            <!--<img src="@/assets/images/pages/juice-fasting/juices-1.jpg" class="img-fluid mb-2" />
            <img src="@/assets/images/pages/juice-fasting/juices-2.jpg" class="img-fluid mb-2" />
            <img src="@/assets/images/pages/juice-fasting/juices-3.jpg" class="img-fluid mb-2" />-->

            <h2 style="color: #83c550" class="mt-3">
              How does juice fasting work?
            </h2>
            <p>
              Having the fibre removed by juicing vegetables means that the pure
              goodness of the juice is easily absorbed by the body and all the
              beneficial nutrients are readily available to be utilised by the
              relevant cells.
            </p>
            <p>
              Your body is naturally trying to detoxify all the time via the
              main detoxification organs. Unwanted toxins are eliminated via the
              colon, liver, kidneys, lungs and the lymphatic system.
            </p>
            <p>
              Juice fasting reduces the stress on the body’s detoxification
              processes, allowing it to naturally reduce your overall toxicity
              level. This process enhances the body's repair and healing
              mechanisms, helping to restore optimal functioning.
            </p>
            <p>
              Scientific studies have shown that fasting for more than a number
              of days stimulates a healing process in the body via a reduction
              in oxidative damage and inflammation, optimisation of energy
              metabolism, and bolstering of cellular protection. When we fast,
              our cells change from 'divide mode' to 'repair mode'.
            </p>
          </div>
        </div>

        <div class="row py-3 text-center">
          <div class="col-md-6 mx-auto">
            <router-link to="/enquiries" class="btn btn-orange-gradient"
              >ENQUIRE NOW</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <hr width="80%" />
        </div>
      </div>
    </div>

    <!--<div class="container py-4">
      <div class="row text-center">
        <div class="col-xs-12 col-md-9 mx-auto">
          <h2 style="color: #83c550;" class="mt-3">Key ingredients for juice fasting</h2>
          <p>Click an ingredient to find out what makes it a key ingredient for juice fasting.</p>
        </div>
      </div>
      <div class="row my-4">
        <div class="col text-center">
          <a class="veg_info">
            <h4 style="color: #008d36;">Cucumber</h4>
            <img src="@/assets/images/pages/juice-fasting/cucumber.jpg" class="img-fluid" />
            <p style="display: none;">
              Highly hydrating and, surprisingly, packed with nutritional value. Cucumber flesh provides folic
              acid, vitamin C, K and A, while the skin is a good source of many important minerals including:
              copper, potassium, magnesium, calcium, iron and phosphorus. It is also an excellent source of
              the trace mineral silica, which helps to maintain the strength of the skin, nails and hair.
              Traditionally used as a skin tonic to treat sunburn and reduce puffy eyes.
            </p>
          </a>
        </div>
        <div class="col text-center">
          <a class="veg_info">
            <h4 style="color: #008d36;">Courgette</h4>
            <img src="@/assets/images/pages/juice-fasting/courgette.jpg" class="img-fluid" />
            <p style="display: none;">
              Rich in vitamin C, potassium and antioxidant carotenes. Courgettes are comparable to more popular
              known anti-cancer vegetables due to their ability to prevent cell mutations. The antioxidant
              benefits of courgettes help to protect the skin from the damaging effects of the sun, while their
              high water content helps prevent dehydration.
            </p>
          </a>
        </div>
        <div class="col text-center">
          <a class="veg_info">
            <h4 style="color: #008d36;">Kale</h4>
            <img src="@/assets/images/pages/juice-fasting/kale.jpg" class="img-fluid" />
            <p style="display: none;">
              Is one of the most highly nutritious vegetables, and is commonly referred to as a ‘superfood’.
              Hardly surprising, as it is an excellent source of vitamin C, offering more than 70% of the
              recommended daily intake. Additionally it contains a whole host of other important nutrients,
              including copper, iron and calcium, as well as vitamin E, B1 and B2. It also has extremely high
              levels of beta-carotene, lutein and zeaxanthin, perfect for protecting the eyes and skin.
            </p>
          </a>
        </div>
        <div class="col text-center">
          <a class="veg_info">
            <h4 style="color: #008d36;">Spinach</h4>
            <img src="@/assets/images/pages/juice-fasting/spinach.jpg" class="img-fluid" />
            <p style="display: none;">
              Spinach contains twice as much iron as most other green vegetables, which may be where it earned
              its reputation as Popeye’s source of strength! Spinach is a highly alkalising vegetable, which
              makes it useful for maintaining a healthy body pH. Studies have identified at least 13 different
              flavonoid compounds in spinach that are known to have antioxidant and anti-cancer properties.
            </p>
          </a>
        </div>
      </div>

      <div class="row my-4 text-center">
        <div class="col-xs-12 col-md-9 mx-auto">
          <h2 style="color: #83c550;" class="mt-3 veg_info_title">Cucumber</h2>
          <p class="veg_info_content">
            Highly hydrating and, surprisingly, packed with nutritional value. Cucumber flesh provides folic acid,
            vitamin C, K and A, while the skin is a good source of many important minerals including: copper,
            potassium, magnesium, calcium, iron and phosphorus. It is also an excellent source of the trace mineral
            silica, which helps to maintain the strength of the skin, nails and hair. Traditionally used as a skin tonic to
            treat sunburn and reduce puffy eyes.
          </p>
        </div>
      </div>

      <div class="row my-4">
        <div class="col text-center">
          <a class="veg_info">
            <h4 style="color: #008d36;">Broccoli</h4>
            <img src="@/assets/images/pages/juice-fasting/brocolli.jpg" class="img-fluid" />
            <p style="display: none;">
              Renowned for its anti-cancer properties because of its high levels of compounds called glucosinolates.
              One of these compounds, called indole-3-carbinol, has been specifically identified for its benefits in
              arresting the growth of both prostate and breast cancer cells. Broccoli increases the livers capacity
              for detoxification.
            </p>
          </a>
        </div>
        <div class="col text-center">
          <a class="veg_info">
            <h4 style="color: #008d36;">Celery</h4>
            <img src="@/assets/images/pages/juice-fasting/celery.jpg" class="img-fluid" />
            <p style="display: none;">
              An excellent source of vitamin C, as well as a good source of B vitamins, potassium and calcium.
              Celery also contains Coumarins – plant chemicals that are thought to enhance the activity of some
              white blood cells. Studies have shown that coumarins can lower blood pressure and tone the vascular
              system. Celery is also thought to lower cholesterol and improve detoxification. Celery juices
              post-exercise are a perfect drink for replacing electrolytes.
            </p>
          </a>
        </div>
        <div class="col text-center">
          <a class="veg_info">
            <h4 style="color: #008d36;">Beetroot</h4>
            <img src="@/assets/images/pages/juice-fasting/beetroot.jpg" class="img-fluid" />
            <p style="display: none;">
              Beetroot juice is a valuable source of antioxidants and naturally occurring compounds that dilate
              the blood vessels, improving blood flow through the body. This gives it excellent blood pressure
              lowering properties It has also been found to support the body during exercise by balancing oxygen
              use and increasing stamina. The brain and heart also benefit from the increased blood flow and
              subsequent elevated oxygen supply.
            </p>
          </a>
        </div>
        <div class="col text-center">
          <a class="veg_info">
            <h4 style="color: #008d36;">Carrots</h4>
            <img src="@/assets/images/pages/juice-fasting/carrots.jpg" class="img-fluid" />
            <p style="display: none;">
              We were all told as children that this carotene packed vegetable can help you to see in the dark.
              Carrots contain beta-carotene which provides protection against macular degeneration and senile
              cataracts, as well as improving night vision. You can also be sure of getting plenty of vitamin K,
              C, B6, biotin, potassium and thiamine. The antioxidant properties of carrots are also cardiovascular
              and cancer protective.
            </p>
          </a>
        </div>
      </div>

      <div class="row my-4">
        <div class="col text-center">
          <a class="veg_info">
            <h4 style="color: #008d36;">Lemon</h4>
            <img src="@/assets/images/pages/juice-fasting/lemons.jpg" class="img-fluid" />
            <p style="display: none;">
              Apart from their fabulous zingy flavour, which helps to eliminate any bitter tastes from the dark
              green leafy vegetables normally found in juices, they are, unsurprisingly, chock-full of vitamin C,
              which helps reduce the duration and severity of colds. Lemons can help in the production of collagen,
              which we need to maintain strong, healthy skin, hair and nails.
            </p>
          </a>
        </div>
        <div class="col text-center">
          <a class="veg_info">
            <h4 style="color: #008d36;">Ginger</h4>
            <img src="@/assets/images/pages/juice-fasting/ginger.jpg" class="img-fluid" />
            <p style="display: none;">
              This is an excellent addition if you enjoy the taste. It gives your juice a little "kick" with tons
              of added health benefits! Ginger has been used as a natural remedy for centuries and works wonders
              for numerous ailments. It can have positive effects on cardiovascular health, including preventing
              atherosclerosis and lowering cholesterol levels. It has also been found to be effective against travel
              sickness and morning sickness.
            </p>
          </a>
        </div>
        <div class="col text-center">
          <a class="veg_info">
            <h4 style="color: #008d36;">Turmeric</h4>
            <img src="@/assets/images/pages/juice-fasting/turmeric.jpg" class="img-fluid" />
            <p style="display: none;">
              Turmeric has received a lot of press coverage lately because of its reported anti-inflammatory and
              anti-cancer properties. Adding it to juices is a great way to benefit from its natural healing
              compounds. It is particularly helpful for inflammatory conditions like rheumatoid arthritis. The
              antioxidant and anti-inflammatory effects of the compound curcumin found in turmeric also helps to
              protect the brain and has been found to be beneficial in those suffering with Alzheimer’s disease.
            </p>
          </a>
        </div>
      </div>
    </div>-->

    <div class="container-fluid pt-4">
      <div class="container">
        <div class="row text-center">
          <div class="col-xs-12 col-md-9 mx-auto">
            <h2 style="color: #83c550" class="mt-3">
              Key ingredients for juice fasting
            </h2>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mx-auto">
            <div class="carousel">
              <div class="slideControls">
                <a class="slidePrev">
                  <i
                    class="fas fa-chevron-circle-left fa-2x"
                    style="color: #8bc34a"
                  ></i>
                </a>
                <a class="slideNext">
                  <i
                    class="fas fa-chevron-circle-right fa-2x"
                    style="color: #8bc34a"
                  ></i>
                </a>
              </div>

              <div id="vegInfo">
                <div class="item text-center">
                  <img
                    src="@/assets/images/pages/juice-fasting/cucumber.jpg"
                    class="img-fluid"
                  />
                  <h4 style="color: #008d36">Cucumber</h4>
                  <p>
                    Highly hydrating and packed with nutritional value, cucumber
                    flesh provides you with folic acid, vitamin C, K and A, and
                    the skin is a good source of many important minerals
                    including copper, potassium, magnesium, calcium, iron and
                    phosphorus. It is also an excellent source of the trace
                    mineral silica, which helps to maintain the strength of your
                    skin, nails and hair. Cucumber can be used as a skin tonic
                    to treat sunburn and reduce puffy eyes.
                  </p>
                </div>

                <div class="item text-center">
                  <img
                    src="@/assets/images/pages/juice-fasting/courgette.jpg"
                    class="img-fluid"
                  />
                  <h4 style="color: #008d36">Courgette</h4>
                  <p>
                    Rich in vitamin C, vitamin A, folate, fibre, potassium and
                    antioxidant carotenes, courgettes contribute to protecting
                    your cardiovascular health, lowering cholesterol and
                    reducing high blood pressure. The antioxidant properties of
                    courgettes help to protect your skin from the damaging
                    effects of the sun.
                  </p>
                </div>

                <div class="item text-center">
                  <img
                    src="@/assets/images/pages/juice-fasting/kale.jpg"
                    class="img-fluid"
                  />
                  <h4 style="color: #008d36">Kale</h4>
                  <p>
                    A highly nutritious vegetable, kale is an excellent source
                    of vitamin C, offering more than 70% of your recommended
                    daily intake. It also contains a host of other important
                    nutrients, including copper, iron and calcium, as well as
                    vitamin E, B1 and B2. Its high levels of beta-carotene,
                    lutein and zeaxanthin are useful for protecting your eyes
                    and skin.
                  </p>
                </div>

                <div class="item text-center">
                  <img
                    src="@/assets/images/pages/juice-fasting/spinach.jpg"
                    class="img-fluid"
                  />
                  <h4 style="color: #008d36">Spinach</h4>
                  <p>
                    Spinach contains twice as much iron as most other green
                    vegetables. A highly alkalising vegetable, it is useful for
                    maintaining a healthy body pH. Studies have identified at
                    least 13 different flavonoid compounds in spinach known to
                    have antioxidant and health-protective properties.
                  </p>
                </div>

                <div class="item text-center">
                  <img
                    src="@/assets/images/pages/juice-fasting/brocolli.jpg"
                    class="img-fluid"
                  />
                  <h4 style="color: #008d36">Broccoli</h4>
                  <p>
                    Renowned for its ability to boost your health because of its
                    high levels of compounds called glucosinolates. One of these
                    compounds, indole-3-carbinol, has been specifically
                    identified for its benefits in potentially inhibiting the
                    growth of both prostate and breast cancer cells. Broccoli
                    increases your liver’s capacity for detoxification.
                  </p>
                </div>

                <div class="item text-center">
                  <img
                    src="@/assets/images/pages/juice-fasting/celery.jpg"
                    class="img-fluid"
                  />
                  <h4 style="color: #008d36">Celery</h4>
                  <p>
                    An excellent source of vitamin C, as well as a good source
                    of B vitamins, potassium and calcium. Celery also contains
                    coumarins, plant chemicals thought to enhance the activity
                    of white blood cells. Studies have shown coumarins can lower
                    your blood pressure and tone the vascular system. Celery is
                    also thought to lower cholesterol and improve your body’s
                    ability to detoxify. Celery juice post-exercise is a perfect
                    drink for replacing electrolytes.
                  </p>
                </div>

                <div class="item text-center">
                  <img
                    src="@/assets/images/pages/juice-fasting/beetroot.jpg"
                    class="img-fluid"
                  />
                  <h4 style="color: #008d36">Beetroot</h4>
                  <p>
                    A valuable source of antioxidants and naturally occurring
                    compounds that dilate your blood vessels, improving blood
                    flow through the body, giving beetroot excellent blood
                    pressure-lowering properties. It has also been found to
                    support the body during exercise by balancing oxygen use and
                    increasing stamina. The brain and heart also benefit from
                    the increased blood flow and subsequent elevated oxygen
                    supply.
                  </p>
                </div>

                <div class="item text-center">
                  <img
                    src="@/assets/images/pages/juice-fasting/carrots.jpg"
                    class="img-fluid"
                  />
                  <h4 style="color: #008d36">Carrots</h4>
                  <p>
                    Carrots contain beta-carotene, known to be beneficial for
                    your eye health, providing protection against macular
                    degeneration and cataracts, as well as improving night
                    vision. They also contain plenty of vitamin K, C, B6,
                    biotin, potassium and thiamine. The antioxidant properties
                    of carrots help protect cardiovascular health and the
                    development of chronic diseases.
                  </p>
                </div>

                <div class="item text-center">
                  <img
                    src="@/assets/images/pages/juice-fasting/lemons.jpg"
                    class="img-fluid"
                  />
                  <h4 style="color: #008d36">Lemon</h4>
                  <p>
                    Lemons are packed with flavonoids and vitamin C, an
                    important vitamin thought to reduce the duration and
                    severity of colds. Lemons aid the production of collagen,
                    required to maintain strong, healthy skin, hair and nails.
                    They are also thought to support cardiovascular health.
                  </p>
                </div>

                <div class="item text-center">
                  <img
                    src="@/assets/images/pages/juice-fasting/ginger.jpg"
                    class="img-fluid"
                  />
                  <h4 style="color: #008d36">Ginger</h4>
                  <p>
                    Ginger has been used as a natural remedy for centuries and
                    is beneficial for numerous ailments. It can have positive
                    effects on your cardiovascular health, including preventing
                    atherosclerosis and lowering cholesterol levels. It has also
                    been found to be effective against travel sickness and
                    morning sickness.
                  </p>
                </div>

                <div class="item text-center">
                  <img
                    src="@/assets/images/pages/juice-fasting/turmeric.jpg"
                    class="img-fluid"
                  />
                  <h4 style="color: #008d36">Turmeric</h4>
                  <p>
                    Turmeric is a natural anti-inflammatory with numerous health
                    boosting properties. Adding it to juices is a great way to
                    benefit from its natural healing compounds. It is
                    particularly helpful for reducing inflammation and so is of
                    benefit in inflammatory conditions like rheumatoid
                    arthritis. The antioxidant and anti-inflammatory effects of
                    the compound curcumin found in turmeric can help protect
                    your brain and have been found to be beneficial in those
                    suffering with Alzheimer’s disease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-4">
      <div class="container">
        <div class="row">
          <hr width="80%" />
        </div>
      </div>
    </div>

    <div class="container-fluid mt-4">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-9 mx-auto text-center">
            <h2 style="color: #83c550" class="mt-3">
              Detoxing at Amchara Health Retreats
            </h2>
            <p>
              Amchara health retreat programmes are designed to help you
              detoxify both physically and mentally. You are immersed in a
              positive and supportive environment, increasing the opportunity
              for your body and mind to start the change process, move toward a
              greater sense of wellbeing and heal.
            </p>

            <p>
              We understand that is important to help you develop long term
              habits to underpin your health now and for the future. Our mission
              is to help you achieve this.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="body-content mx-auto">
      <locations></locations>
    </div>

    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <hr width="80%" />
        </div>
      </div>
    </div>

    <!-- Testimonials -->
    <div class="mt-0">
      <testimonials></testimonials>
    </div>

    <!-- Newsletter -->
    <div>
      <newsletter-cta></newsletter-cta>
    </div>
  </div>
</template>

<script>
import Testimonials from "./page-partials/Testimonials";
import NewsletterCta from "./page-partials/NewsletterCta";
import BenefitsOfJuiceFasting from "./page-partials/BenefitsOfJuiceFasting";
import Locations from "./page-partials/Locations";
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        i => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        i => i.slug == this.$route.path.replace("/", "")
      ).description
      // titleTemplate: null,
    };
  },
  data() {
    return {
      bgImages: [require("@/assets//images/pages/juice-fasting/header-2.jpg")]
    };
  },
  mounted() {
    var sliders = {
      slider_1: {
        outer_left: {
          title: "Juices with vegetables",
          subheader: "",
          description:
            "Fructose-free juicing will include freshly made organic vegetable juices with green vegetables such as cucumbers, courgettes or spinach. Beetroot and carrots can be added, which contain some natural sugars, fructose. Breaking down these sugars adds a minimal load to the liver, however, these vegetables contain high levels of beneficial beta-carotene."
        },
        inner_left: {
          title: "Water fasting",
          subheader: "",
          description:
            "Water fasting is when you only consume water. Water fasting has to be carried out carefully and is not suitable for everyone. Our Personalised Health practitioners will assess whether this is suitable for you."
        },
        middle: {
          title: "Juices with fruits",
          subheader: "",
          description:
            "Supplementing your vegetable juice with fruits, such as apples and pears, which are high in fructose, can make your juices more palatable, with a sweeter taste. Often people starting on this move to pure vegetable juicing after a few days."
        },
        inner_right: {
          title: "Light fasting",
          subheader: "",
          description:
            "A mixture of juices, smoothies and soups is another option we often recommend. It’s all liquid but includes fibre. Often this is appropriate for clients who have fatigue, where we’re aiming to both detox and nourish at the same time."
        },
        outer_right: {
          title: "Intermittent fasting",
          subheader: "",
          description:
            "Having recently become increasingly popular, intermittent fasting means juicing or reducing calories for 2 days per week. This is often a good option for people with particular medical conditions or if you are new to fasting."
        }
      }
    };

    $(document).ready(function() {
      // on page load
      $(".circle_slider").each(function() {
        var sliderID = $(this).data("slider-id");

        if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
          $(this)
            .find(".green_circle_outer_left_text")
            .html(sliders["slider_" + sliderID]["outer_left"]["title"]);
        }
        $(this)
          .find(".green_circle_inner_left_text")
          .html(sliders["slider_" + sliderID]["inner_left"]["title"]);
        $(this)
          .find(".selected_circle_text")
          .html(sliders["slider_" + sliderID]["middle"]["title"]);
        $(this)
          .find(".green_circle_inner_right_text")
          .html(sliders["slider_" + sliderID]["inner_right"]["title"]);
        if (sliders["slider_" + sliderID]["outer_right"] != undefined) {
          $(this)
            .find(".green_circle_outer_right_text")
            .html(sliders["slider_" + sliderID]["outer_right"]["title"]);
        }

        $(this)
          .find(".selected_circle_subheader")
          .html(sliders["slider_" + sliderID]["middle"]["subheader"]);
        $(this)
          .find(".selected_circle_description")
          .html(sliders["slider_" + sliderID]["middle"]["description"]);
      });

      // desktop event listener
      $(".green_circle").click(function() {
        var this_circle_slider = $(this).closest(".circle_slider");
        var sliderID = this_circle_slider.data("slider-id");
        var position = $(this).data("position");

        // switch the newly selected object with the previously selected object
        var previously_selected_obj = sliders["slider_" + sliderID]["middle"];
        var newly_selected_obj = sliders["slider_" + sliderID][position];
        sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        sliders["slider_" + sliderID][position] = previously_selected_obj;

        // now update the objects on screen
        $(this)
          .find(".green_circle_" + position + "_text")
          .html(previously_selected_obj.title);
        $(this_circle_slider)
          .find(".selected_circle_text")
          .html(newly_selected_obj.title);
        $(this_circle_slider)
          .find(".selected_circle_subheader")
          .html(newly_selected_obj.subheader);
        $(this_circle_slider)
          .find(".selected_circle_description")
          .html(newly_selected_obj.description);
      });

      // mobile event listener
      $(".slider-arrow").click(function() {
        var this_circle_slider = $(this).closest(".circle_slider");
        var sliderID = this_circle_slider.data("slider-id");
        var sliderRotate = $(this).data("rotate");

        // move all objects up or down one depending on whether left (down) or right (up) arrow was pressed
        if (sliderRotate == "left") {
          var newly_selected_obj = sliders["slider_" + sliderID]["inner_left"];
          if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
            // 5 circle slider
            sliders["slider_" + sliderID]["inner_left"] =
              sliders["slider_" + sliderID]["outer_left"];
            sliders["slider_" + sliderID]["outer_left"] =
              sliders["slider_" + sliderID]["outer_right"];
            sliders["slider_" + sliderID]["outer_right"] =
              sliders["slider_" + sliderID]["inner_right"];
          } else {
            // 3 circle slider
            sliders["slider_" + sliderID]["inner_left"] =
              sliders["slider_" + sliderID]["inner_right"];
          }
          sliders["slider_" + sliderID]["inner_right"] =
            sliders["slider_" + sliderID]["middle"];
          sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        } else if (sliderRotate == "right") {
          var newly_selected_obj = sliders["slider_" + sliderID]["inner_right"];
          if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
            // 5 circle slider
            sliders["slider_" + sliderID]["inner_right"] =
              sliders["slider_" + sliderID]["outer_right"];
            sliders["slider_" + sliderID]["outer_right"] =
              sliders["slider_" + sliderID]["outer_left"];
            sliders["slider_" + sliderID]["outer_left"] =
              sliders["slider_" + sliderID]["inner_left"];
          } else {
            // 3 circle slider
            sliders["slider_" + sliderID]["inner_right"] =
              sliders["slider_" + sliderID]["inner_left"];
          }
          sliders["slider_" + sliderID]["inner_left"] =
            sliders["slider_" + sliderID]["middle"];
          sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        }

        // now update the objects on screen
        // including the (hidden on mobile) green circles incase
        // they are on desktop and expand the vieport
        if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
          $(this_circle_slider)
            .find(".green_circle_outer_left_text")
            .html(sliders["slider_" + sliderID]["outer_left"]["title"]);
        }
        $(this_circle_slider)
          .find(".green_circle_inner_left_text")
          .html(sliders["slider_" + sliderID]["inner_left"]["title"]);
        $(this_circle_slider)
          .find(".selected_circle_text")
          .html(sliders["slider_" + sliderID]["middle"]["title"]);
        $(this_circle_slider)
          .find(".green_circle_inner_right_text")
          .html(sliders["slider_" + sliderID]["inner_right"]["title"]);
        if (sliders["slider_" + sliderID]["outer_right"] != undefined) {
          $(this_circle_slider)
            .find(".green_circle_outer_right_text")
            .html(sliders["slider_" + sliderID]["outer_right"]["title"]);
        }

        $(this_circle_slider)
          .find(".selected_circle_subheader")
          .html(sliders["slider_" + sliderID]["middle"]["subheader"]);
        $(this_circle_slider)
          .find(".selected_circle_description")
          .html(sliders["slider_" + sliderID]["middle"]["description"]);
      });

      /*$(".veg_info").on("click", function() {
        var title = $(this)
          .find("h4")
          .html();
        var content = $(this)
          .find("p")
          .html();

        $(".veg_info_title").html(title);
        $(".veg_info_content").html(content);
      });*/

      var vegSlider = $("#vegInfo").lightSlider({
        onSliderLoad: function(el) {
          var maxHeight = 0,
            container = $(el),
            children = container.children();
          children.each(function() {
            var childHeight = $(this).height();
            if (childHeight > maxHeight) {
              maxHeight = childHeight;
            }
          });
          container.height(maxHeight);
        },
        controls: false,
        item: 3,
        loop: false,
        slideMove: 1,
        easing: "cubic-bezier(0.25, 0, 0.25, 1)",
        pager: false,
        slideMargin: 30,
        speed: 600,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              item: 2,
              slideMove: 1,
              slideMargin: 10
            }
          },
          {
            breakpoint: 480,
            settings: {
              item: 1,
              slideMove: 1,
              slideMargin: 10
            }
          }
        ]
      });

      $(".slideControls .slidePrev").click(function() {
        vegSlider.goToPrevSlide();
      });

      $(".slideControls .slideNext").click(function() {
        vegSlider.goToNextSlide();
      });

      $(".uk_thumb").on("click", function() {
        var thumb = $(this)
          .find("img")
          .attr("src");
        $(".main_uk_image").attr("src", thumb);
      });

      $(".malta_thumb").on("click", function() {
        var thumb = $(this)
          .find("img")
          .attr("src");
        $(".main_malta_image").attr("src", thumb);
      });
    });
  },
  components: {
    Testimonials,
    NewsletterCta,
    BenefitsOfJuiceFasting,
    Locations
  }
};
</script>

<style>
</style>