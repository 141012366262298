<template>
  <!-- Benefits of juice fasting Section -->
  <div class="container-fluid mb-4">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <div class="green-header-2-text text-center">
          Benefits of juice fasting
        </div>
        <div class="row">
          <div class="col-md-7 my-auto px-5 px-md-3 pe-lg-5">
            <div class="row">
              <div class="col-md-6">
                <ul class="home-bullet-points-left">
                  <li class="p-list-item mb-3 mb-md-0">Weight management</li>
                  <li class="p-list-item mb-3 mb-md-0">Improved digestion</li>
                  <li class="p-list-item mb-3 mb-md-0">
                    Regular bowel movements
                  </li>
                  <li class="p-list-item mb-3 mb-md-0">Reduces IGF-1 levels</li>
                  <li class="p-list-item mb-3 mb-md-0">
                    Reduces blood sugar levels
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="home-bullet-points-right">
                  <li class="p-list-item mb-3 mb-md-0">
                    Reduces blood pressure
                  </li>
                  <li class="p-list-item mb-3 mb-md-0">
                    Reduces impact of oxidative stress
                  </li>
                  <li class="p-list-item mb-3 mb-md-0">
                    Improves mitochondrial energy production
                  </li>
                  <li class="p-list-item mb-3 mb-md-0">
                    Increases longevity as part of a healthy lifestyle
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-5 my-4 text-center">
            <img
              width="800"
              src="@/assets/images/pages/juice-fasting/benefits-of-juice-fasting.jpg"
              alt="Personalised image"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 text-center">
      <div class="col">
        <div>
          <router-link
            to="/enquiries"
            class="btn btn-orange-gradient enquire-now-button"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>